import React, { useState, useEffect } from "react";
import Logo from "../navbar/logo";
import { Link } from "gatsby";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

// data
const links = [
  {
    text: "Home",
    url: "/",
  },
  {
    text: "About",
    url: "/about",
  },
  {
    text: "Events",
    url: "/events",
  },
  {
    text: "Wundar",
    url: "/wundar",
  },
  {
    text: "Contact",
    url: "/contact",
  },
];

const NavBar = (props) => {
  const [mobileNav, setMobileNav] = useState(props);

  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="flex flex-row justify-between items-center font-body px-5 py-5 md:px-10 md:py-10 lg:px-24 xl:px-32">
            <div className="flex-shrink-0">
              <Logo></Logo>
            </div>
            <div className="hidden md:block sm:ml-6">
              <div className="flex space-x-4">
                {links.map((link) => (
                  <div className="px-5 logo text-md lg:text-xl xl:text-2xl font-lexend-deca ">
                    <span>
                      <Link to={`${link.url}`}>{link.text}</Link>
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className="-mr-2 flex md:hidden">
              {/* Mobile menu button */}
              <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-blue ">
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <XIcon
                    onClick={() => setMobileNav(false)}
                    className="block h-6 w-6 "
                    aria-hidden="true"
                  />
                ) : (
                  <MenuIcon
                    onClick={() => setMobileNav(true)}
                    className="block h-6 w-6"
                    aria-hidden="true"
                  />
                )}
              </Disclosure.Button>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 h-[100vh]">
              {links.map((link) => (
                <Disclosure.Button
                  as="a"
                  href={`${link.url}`}
                  className="text-black block px-5 py-5 rounded-md text-xl font-lexend-deca hover:bg-blue hover:text-white"
                >
                  <Link to={`${link.url}`}>{link.text}</Link>
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default NavBar;
