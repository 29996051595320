import * as React from "react";
import logo from "../../images/logo.png";
import { Link } from "gatsby";
import logoBlack from '../../images/logoBlack.png';

const Logo = ({type}) => {
  return (
    <Link to="/" className="logo">
      {
        type == 'footer' ? <div>
          <img src={logoBlack}/>
        </div> :
         <div className="flex items-center font-body ">
         <img src={logo} alt="logo" className="h-15 w-12 md:h-17 md:w-14" />
         <h3 className="text-xl pl-3 lg:text-2xl font-bold lg:pl-6">
           COMM-STEM
         </h3>
       </div>

      }
     
    </Link>
  );
};

export default Logo;
